
































































































































@import '@design';

.single-location--header {
  @media only screen and (max-width: 321px) {
    .v-btn {
      height: 38px;
      padding: 10px !important;
    }
  }
}

.single-location {
  .featured-image {
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: cover;
  }
}

.blue-oak-web-menu {
  &.grid {
    max-width: 1440px;
    margin-right: auto;
    margin-left: auto;

    @media (max-width: 1200px) {
      grid-template-areas: '. left center .' '. right . .' !important;
      grid-template-columns: 1fr 4fr 4fr 1fr !important;
    }

    @media (max-width: 900px) {
      grid-template-areas: 'left center' 'right .' !important;
      grid-template-columns: 1fr 1fr !important;
    }

    @media (max-width: 650px) {
      grid-template-areas: 'left' 'center' 'right' !important;
      grid-template-columns: 1fr !important;
      .region {
        margin: 0 !important;
      }
    }
  }
}

.blue-oak-menu-section .section-content > .container {
  @media (max-width: 900px) {
    padding: 7px;
  }
  @media (max-width: 650px) {
    padding: 3px;
  }
}
